/**
 *
 * 无接口前缀工程
 */

const PREFIX = ``;

// 接口列表
export const Default = {
};
