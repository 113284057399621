/**
 * 根模块路由，不属于任何模块的路由可放置在这.
 *
 * meta 可配置参数
 * @param {boolean} keepAlive 是否缓存页面,一般默认为false
 * @param {string} title 页面标题
 * @param {string} permission 页面访问权限码,无此属性则不设权限
 */
export default [
  {
    path: "",
    component: () => import("@/views/layout/basic/basic.vue"),
    redirect: "/",
    children: [
      {
        path: "/",
        name: "home",
        component: () => import("@/views/home/home.vue"),
        meta: {
          keepAlive: true,
          title: "首页"
        }
      }
    ]
  },
    {
        path: "/home",
        name: "home",
        component: () => import("@/views/home/home.vue"),
        meta: {
            keepAlive: false,
            title: "首页"
        }
    },
  {
    path: "/mine",
    name: "mine",
    component: () => import("@/views/mine/mine.vue"),
    meta: {
      keepAlive: false,
      title: "联系我们"
    }
  },
  {
    path: "/news",
    name: "news",
    component: () => import("@/views/news/news.vue"),
    meta: {
      keepAlive: false,
      title: "公司新闻"
    }
  },
  {
    path: "/product",
    name: "product",
    component: () => import("@/views/product/product.vue"),
    meta: {
      keepAlive: false,
      title: "产品方案"
    }
  },
  {
    path: "/companyprofile",
    name: "companyprofile",
    component: () => import("@/views/companyprofile/companyprofile.vue"),
    meta: {
      keepAlive: false,
      title: "公司简介"
    }
  },
    {
        path: "/productdetail1",
        name: "productdetail1",
        component: () => import("@/views/productdetail/productdetail1.vue"),
        meta: {
            keepAlive: false,
            title: "方案详情"
        }
    },{
        path: "/productdetail2",
        name: "productdetail2",
        component: () => import("@/views/productdetail/productdetail2.vue"),
        meta: {
            keepAlive: false,
            title: "方案详情"
        }
    },{
        path: "/productdetail3",
        name: "productdetail3",
        component: () => import("@/views/productdetail/productdetail3.vue"),
        meta: {
            keepAlive: false,
            title: "方案详情"
        }
    },{
        path: "/productdetail4",
        name: "productdetail4",
        component: () => import("@/views/productdetail/productdetail4.vue"),
        meta: {
            keepAlive: false,
            title: "方案详情"
        }
    },{
        path: "/productdetail5",
        name: "productdetail5",
        component: () => import("@/views/productdetail/productdetail5.vue"),
        meta: {
            keepAlive: false,
            title: "方案详情"
        }
    },{
        path: "/productdetail6",
        name: "productdetail6",
        component: () => import("@/views/productdetail/productdetail6.vue"),
        meta: {
            keepAlive: false,
            title: "方案详情"
        }
    },{
        path: "/productdetail7",
        name: "productdetail7",
        component: () => import("@/views/productdetail/productdetail7.vue"),
        meta: {
            keepAlive: false,
            title: "方案详情"
        }
    },
    {
        path: "/newsdetail",
        name: "newsdetail",
        component: () => import("@/views/newsdetail/newsdetail.vue"),
        meta: {
            keepAlive: false,
            title: "新闻详情"
        }
    },
    {
        path: "/newsdetail1",
        name: "newsdetail1",
        component: () => import("@/views/newsdetail/newsdetail1.vue"),
        meta: {
            keepAlive: false,
            title: "新闻详情"
        }
    },
    {
        path: "/newsdetail2",
        name: "newsdetail2",
        component: () => import("@/views/newsdetail/newsdetail2.vue"),
        meta: {
            keepAlive: false,
            title: "新闻详情"
        }
    },
    {
        path: "/newsdetail3",
        name: "newsdetail3",
        component: () => import("@/views/newsdetail/newsdetail3.vue"),
        meta: {
            keepAlive: false,
            title: "新闻详情"
        }
    },
    {
        path: "/gime",
        name: "gime",
        component: () => import("@/views/gime/gime.vue"),
        meta: {
            keepAlive: false,
            title: "游戏"
        }
    }
];
