import Vue from "vue";
import Router from "vue-router";
import ROOT_ROUTES from "./root-router";


Vue.use(Router);

const router = new Router({
  routes: [
    ...ROOT_ROUTES,
    {
      // 这里匹配404链接
      path: "*",
      component: () => import("@/views/404/404.vue"),
      meta: {
        icon: "",
        keepAlive: true,
        title: "找不到页面"
      }
    }
  ],
  base: process.env.BASE_URL,
  mode: "hash"
});

export default router;
